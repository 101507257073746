<template>
  <p-dialog
    :visible="visible"
    draggable
    modal
    header="Add Actor"
    style="
      overflow: hidden;
      margin: 1rem;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      display: flex;
      justify-content: center;
    "
    :style="{ width: '25rem' }"
    @hide="emitCloseDialog"
    class="container"
  >
    <div class="row">
      <div class="col">
        <img
          alt="Actor Image"
          class="rounded"
          :src="`https://media.themoviedb.org/t/p/w600_and_h900_bestv2${actor.profile_path}`"
          width="100"
        />
        <h5>{{ actor.name }}</h5>
        <p-input-text
          type="text"
          v-model="characterName"
          placeholder="Character Name"
        />
      </div>
    </div>

    <template #footer>
      <p-button
        type="button"
        label="Cancel"
        severity="secondary"
        @click="emitCloseDialog"
      ></p-button>
      <p-button @click="addCharacter">Add Character</p-button>
    </template>
  </p-dialog>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    actor: Object,
    visible: Boolean,
  },
  emits: ["add-character", "close-dialog"],
  setup(props, { emit }) {
    const characterName = ref("");

    const addCharacter = () => {
      emit("add-character", {
        actor: props.actor,
        characterName: characterName.value,
      });
      characterName.value = "";
      emitCloseDialog(); // Close the dialog after adding the character
    };

    const emitCloseDialog = () => {
      emit("close-dialog");
      characterName.value = "";
    };

    return {
      characterName,
      addCharacter,
      emitCloseDialog,
    };
  },
};
</script>

<style lang="css">
.p-card-title,
.p-dialog-title {
  color: white;
  font-size: 2.2em !important;
  text-align: center;
}

.p-card-subtitle,
h5 {
  color: white !important;
  text-align: center;
}
</style>
