<template>
  <div>
    <p-auto-complete
      type="text"
      v-model="query"
      :suggestions="results"
      @input="searchActors"
      placeholder="Search for an actor..."
      optionLabel="name"
    >
      <template #option="slotProps">
        <div class="row" @click="selectActor(slotProps.option)">
          <div class="col">
            <img
              :alt="slotProps.option.name"
              :src="`https://media.themoviedb.org/t/p/w600_and_h900_bestv2${slotProps.option.profile_path}`"
              style="width: 50px"
              class="rounded"
            />
          </div>
          <div class="col" style="font-size: 1em; padding: 20px">
            {{ slotProps.option.name }}
          </div>
        </div>
      </template>
    </p-auto-complete>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

export default {
  emits: ["actor-selected"],
  setup(props, { emit }) {
    const query = ref("");
    const search = ref("");
    const results = ref([]);

    const searchActors = () => {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}search/person?query=${query.value}&api_key=${process.env.VUE_APP_API_KEY}&include_adult=false&language=en-US&page=1`,
      };
      if (query.value.length > 2) {
        axios
          .request(options)
          .then(function (response) {
            results.value = response.data.results;
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        results.value = [];
      }
    };

    const selectActor = (actor) => {
      emit("actor-selected", actor);
      results.value = [];
      query.value = "";
    };

    return {
      query,
      results,
      searchActors,
      selectActor,
      search,
    };
  },
};
</script>
