<template>
  <div id="app ">
    <MainHeader />
    <div id="main-content" class="container my-4 mh-100">
      <div class="row">
        <div class="container">
          <h3 class="text-center">Instructions:</h3>
          <p>
            To begin, enter the title of the Movie or Book that you would like
            to create a fantasy cast for. The title will appear in a container
            below.
          </p>
          <p>
            Then you can begin to search for the actor you had in mind to play a
            certain character for that movie or book. They will also show up
            below.
          </p>
          <p>
            Once you are done, you can click the button at the bottom of the
            page to save the image and share wherever you like!
          </p>
        </div>
      </div>
      <ActorList />
    </div>
    <Footer />
  </div>
</template>

<script>
import ActorList from "./components/ActorList.vue";
import MainHeader from "./components/MainHeader.vue";
import Footer from "./components/FooterComponent.vue";

export default {
  components: {
    ActorList,
    MainHeader,
    Footer,
  },
};
</script>
